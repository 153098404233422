import React from "react";

import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    ActivityIndicator,
//    Slider
} from "react-native";
import { enviarInfo } from "../Utility/Api";
import { MaterialIcons } from "@expo/vector-icons";
import { Audio } from 'expo-av';
import Slider from "react-native-slider";
import Toast from "react-native-root-toast";

export default class HimnosPartido extends React.PureComponent {
    constructor(props) {
        super(props);

        this.himnoLargo = null;
        this.himnoCorto = null;

        const hayHimnoLargo = this.props.partido.himno && this.props.partido.himno != null && this.props.partido.himno != "";
        const hayHimnoCorto = this.props.partido.himnoCorto && this.props.partido.himnoCorto != null && this.props.partido.himnoCorto != "";

        this.state = {
            hayHimnoLargo: hayHimnoLargo,
            hayHimnoCorto: hayHimnoCorto,
            playingHimnoLargo: false,
            playingHimnoCorto: false,
            loadingHimnoLargo: true,
            loadingHimnoCorto: true,
            seekingHimnoLargo: false,
            seekingHimnoCorto: false
        }
    }

    componentDidMount() {
        if (this.state.hayHimnoLargo) {
            this.cargarHimnoLargo();
            if (this.state.hayHimnoCorto) {
                this.cargarHimnoCorto();
            }
        }
    }

    componentWillUnmount() {
        this.destruirHimnoLargo();
        this.destruirHimnoCorto();
    }

    himnoLargoListener = estadoHimnoLargo => {
        if (!estadoHimnoLargo.isLoaded) {
            // Update your UI for the unloaded state
            this.setState({
                playingHimnoLargo: false,
                loadingHimnoLargo: true
            });
            if (estadoHimnoLargo.error) {
                console.warn(`ERROR HIMNOLARGO: ${estadoHimnoLargo.error}`);

                // Send Expo team the error on Slack or the forums so we can help you debug!
                this.destruirHimnoLargo();
                this.setState({ hayHimnoLargo: false });

                const mensaje = "Error de conexión con el audio del dispositivo, por favor, reinicie la aplicación.";
                Toast.show(mensaje, {
                    duration: Toast.durations.LONG,
                    position: Toast.positions.BOTTOM,
                    shadow: true,
                    animation: true,
                    hideOnPress: true,
                    delay: 0,
                    backgroundColor: "#226360",
                    opacity: 0.9,
                    onShow: () => { },
                    onShown: () => { },
                    onHide: () => { },
                    onHidden: () => { }
                });
            }
        } else if (this.himnoLargo != null && estadoHimnoLargo.isLoaded) {
            // Update your UI for the loaded state
            this.setState({
                loadingHimnoLargo: false,
                totalHimnoLargo: estadoHimnoLargo.durationMillis,
                posicionHimnoLargo: estadoHimnoLargo.positionMillis
            });

            if (estadoHimnoLargo.isPlaying) {
                // Update your UI for the playing state
                this.setState({
                    playingHimnoLargo: true
                });
            } else {
                // Update your UI for the paused state
                this.setState({
                    playingHimnoLargo: false
                });
            }

            if (estadoHimnoLargo.isBuffering) {
                // Update your UI for the buffering state
            }

            if (estadoHimnoLargo.didJustFinish && !estadoHimnoLargo.isLooping) {
                // The player has just finished playing and will stop. Maybe you want to play something else?
                //console.warn("himno largo terminado") //habria que cambiar el pause por play
                this.stopHimnoLargo();
            }
        }
    }

    himnoCortoListener = estadoHimnoCorto => {
        if (!estadoHimnoCorto.isLoaded) {
            // Update your UI for the unloaded state
            this.setState({
                playingHimnoCorto: false,
                loadingHimnoCorto: true
            });
            if (estadoHimnoCorto.error) {
                console.warn(`ERROR HIMNOCORTO: ${estadoHimnoCorto.error}`);

                // Send Expo team the error on Slack or the forums so we can help you debug!
                this.destruirHimnoCorto();
                this.setState({ hayHimnoCorto: false });

                const mensaje = "Error de conexión con el audio del dispositivo, por favor, reinicie la aplicación.";
                Toast.show(mensaje, {
                    duration: Toast.durations.LONG,
                    position: Toast.positions.BOTTOM,
                    shadow: true,
                    animation: true,
                    hideOnPress: true,
                    delay: 0,
                    backgroundColor: "#226360",
                    opacity: 0.9,
                    onShow: () => { },
                    onShown: () => { },
                    onHide: () => { },
                    onHidden: () => { }
                });
            }
        } else if (this.himnoCorto != null && estadoHimnoCorto.isLoaded) {
            // Update your UI for the loaded state
            this.setState({
                loadingHimnoCorto: false,
                totalHimnoCorto: estadoHimnoCorto.durationMillis,
                posicionHimnoCorto: estadoHimnoCorto.positionMillis
            });

            if (estadoHimnoCorto.isPlaying) {
                // Update your UI for the playing state
                this.setState({
                    playingHimnoCorto: true
                });
            } else {
                // Update your UI for the paused state
                this.setState({
                    playingHimnoCorto: false
                });
            }

            if (estadoHimnoCorto.isBuffering) {
                // Update your UI for the buffering state
            }

            if (estadoHimnoCorto.didJustFinish && !estadoHimnoCorto.isLooping) {
                // The player has just finished playing and will stop. Maybe you want to play something else?
                //console.warn("himno corto terminado") //habria que cambiar el pause por play
                this.stopHimnoCorto();
            }
        }
    }

    getPosicionHimnoLargo = () => {
        if (this.himnoLargo != null && this.state.posicionHimnoLargo != null && this.state.totalHimnoLargo != null) {
            return this.state.posicionHimnoLargo / this.state.totalHimnoLargo;
        }
        return 0;
    }

    getPosicionHimnoCorto = () => {
        if (this.himnoCorto != null && this.state.posicionHimnoCorto != null && this.state.totalHimnoCorto != null) {
            return this.state.posicionHimnoCorto / this.state.totalHimnoCorto;
        }
        return 0;
    }

    cargarHimnoLargo = async () => {
        try {
            this.himnoLargo = new Audio.Sound();
            await this.himnoLargo.loadAsync(this.props.partido.himno);
            this.himnoLargo.setOnPlaybackStatusUpdate(this.himnoLargoListener);
        } catch (error) {
            console.warn("error al cargar himnoLargo " + error);
        }
    }

    cargarHimnoCorto = async () => {
        try {
            this.himnoCorto = new Audio.Sound();
            await this.himnoCorto.loadAsync(this.props.partido.himnoCorto);
            this.himnoCorto.setOnPlaybackStatusUpdate(this.himnoCortoListener);
        } catch (error) {
            console.warn("error al cargar himnoCorto " + error);
        }
    }

    destruirHimnoLargo = async () => {
        try {
            if (this.himnoLargo != null) {
                this.himnoLargo.setOnPlaybackStatusUpdate(null);
                await this.himnoLargo.unloadAsync();
                this.himnoLargo = null;
            }
        } catch (error) { }
    }

    destruirHimnoCorto = async () => {
        try {
            if (this.himnoCorto != null) {
                this.himnoCorto.setOnPlaybackStatusUpdate(null);
                await this.himnoCorto.unloadAsync();
                this.himnoCorto = null;
            }
        } catch (error) { }
    }

    playHimnoLargo = async () => {
        try {
            if (this.state.playingHimnoCorto) {
                this.pauseHimnoCorto();
            }

            if (this.state.playingHimnoLargo) {
                this.pauseHimnoLargo();
            } else {
                await this.himnoLargo.playAsync();
                enviarInfo(this.props.partido.siglas, "himnolargo");
            }
        } catch (error) {
            console.warn("error al sonar himnoLargo " + error);
        }
    }

    playHimnoCorto = async () => {
        try {
            if (this.state.playingHimnoLargo) {
                this.pauseHimnoLargo();
            }

            if (this.state.playingHimnoCorto) {
                this.pauseHimnoCorto();
            } else {
                await this.himnoCorto.playAsync();
                enviarInfo(this.props.partido.siglas, "himnocorto");
            }
        } catch (error) {
            console.warn("error al sonar himnoCorto " + error);
        }
    }

    pauseHimnoLargo = async () => {
        try {
            if (this.state.playingHimnoLargo) {
                await this.himnoLargo.pauseAsync();
            }
        } catch (error) {
            console.warn("error al pausar himnoLargo " + error);
        }
    }

    pauseHimnoCorto = async () => {
        try {
            if (this.state.playingHimnoCorto) {
                await this.himnoCorto.pauseAsync();
            }
        } catch (error) {
            console.warn("error al pausar himnoCorto " + error);
        }
    }

    stopHimnoLargo = async () => {
        try {
            await this.himnoLargo.stopAsync();
        } catch (error) {
            console.warn("error al parar himnoLargo " + error);
        }
    }

    stopHimnoCorto = async () => {
        try {
            await this.himnoCorto.stopAsync();
        } catch (error) {
            console.warn("error al parar himnoCorto " + error);
        }
    }

    moviendoHimnoLargo = () => {
        if (this.himnoLargo != null && !this.state.seekingHimnoLargo) {
            this.setState({
                seekingHimnoLargo: true
            });
            this.himnoLargo.pauseAsync();
        }
    }

    moviendoHimnoCorto = () => {
        if (this.himnoCorto != null && !this.state.seekingHimnoCorto) {
            this.setState({
                seekingHimnoCorto: true
            });
            this.himnoCorto.pauseAsync();
        }
    }

    movidoHimnoLargo = async (value) => {
        if (this.himnoLargo != null && this.state.totalHimnoLargo != null) {
            const seekPosition = value * this.state.totalHimnoLargo;
            if (this.state.playingHimnoLargo) {
                await this.himnoLargo.playFromPositionAsync(seekPosition);
            } else {
                await this.himnoLargo.setPositionAsync(seekPosition);
            }
        }
        this.setState({
            seekingHimnoLargo: false
        });
    }

    movidoHimnoCorto = async (value) => {
        if (this.himnoCorto != null && this.state.totalHimnoCorto != null) {
            const seekPosition = value * this.state.totalHimnoCorto;
            if (this.state.playingHimnoCorto) {
                await this.himnoCorto.playFromPositionAsync(seekPosition);
            } else {
                await this.himnoCorto.setPositionAsync(seekPosition);
            }
        }
        this.setState({
            seekingHimnoCorto: false
        });
    }

    render() {
        const botonPausa = <MaterialIcons name={"pause-circle-outline"} size={45} color="#349994" />;
        const botonPlay = <MaterialIcons name={"play-circle-outline"} size={45} color="#349994" />;
        const iconoCargando = <ActivityIndicator size="large" color="#349994" style={{ width: 45, height: 45 }} />;
        return (
            <View>
                {this.state.hayHimnoLargo && <View>
                    <View style={{ padding: 10, marginBottom: 5 }}>
                        <Text style={{ width: "100%", textAlign: "center", fontSize: 19, fontWeight: "bold" }}>
                            {"Himno\n"}
                        </Text>
                        <Text style={styles.textoVersion}>Versión completa</Text>
                        <View style={[styles.fila, { paddingLeft: 18, alignItems: 'center' }]}>
                            <TouchableOpacity
                                disabled={this.state.loadingHimnoLargo}
                                onPress={() => {
                                    this.playHimnoLargo();
                                }}>
                                {(this.state.loadingHimnoLargo && iconoCargando) ||
                                    (this.state.playingHimnoLargo && botonPausa) || botonPlay
                                }
                            </TouchableOpacity>
                            <View style={{ flex: 1, marginLeft: 15, marginRight: 25 }}>
                                <Slider
                                    value={this.getPosicionHimnoLargo()}
                                    onValueChange={this.moviendoHimnoLargo}
                                    onSlidingComplete={this.movidoHimnoLargo}
                                    disabled={this.state.loadingHimnoLargo}
                                    minimumTrackTintColor='#42c4bd'
                                    maximumTrackTintColor='#d3d3d3'
                                    thumbTintColor='#349994'
                                    thumbStyle={styles.thumbStyle}
                                    trackStyle={styles.trackStyle}
                                />
                            </View>
                        </View>
                        {this.state.hayHimnoCorto &&
                            <View>
                                <Text style={styles.textoVersion}>Versión corta</Text>
                                <View style={[styles.fila, { paddingLeft: 18, alignItems: 'center' }]}>
                                    <TouchableOpacity
                                        disabled={this.state.loadingHimnoCorto}
                                        onPress={() => {
                                            this.playHimnoCorto();
                                        }}>
                                        {(this.state.loadingHimnoCorto && iconoCargando) ||
                                            (this.state.playingHimnoCorto && botonPausa) || botonPlay
                                        }
                                    </TouchableOpacity>
                                    <View style={{ flex: 1, marginLeft: 15, marginRight: 25 }}>
                                        <Slider
                                            value={this.getPosicionHimnoCorto()}
                                            onValueChange={this.moviendoHimnoCorto}
                                            onSlidingComplete={this.movidoHimnoCorto}
                                            disabled={this.state.loadingHimnoCorto}
                                            minimumTrackTintColor='#42c4bd'
                                            maximumTrackTintColor='#d3d3d3'
                                            thumbTintColor='#349994'
                                            thumbStyle={styles.thumbStyle}
                                            trackStyle={styles.trackStyle}
                                        />
                                    </View>
                                </View>
                            </View>}
                    </View>
                    <View style={styles.horizontalLine} />
                </View>}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    horizontalLine: {
        borderBottomColor: "#349994",
        borderBottomWidth: 1,
        width: "100%",
        marginVertical: 10
    },
    fila: {
        flexDirection: "row",
        width: "100%",
        height: "auto"
    },
    textoVersion: {
        width: "100%",
        marginLeft: 20,
        marginTop: 15,
        marginBottom: 10,
        color: "#349994"
    },
    thumbStyle: {
        width: 15,
        height: 15,
        borderRadius: 15 / 2
    },
    trackStyle: {
        height: 3
    }
});