import { createAppContainer } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';

import Principal from "./Views/Principal";
import Partido from "./Views/Partido";

const App = createStackNavigator(
    {
        Principal: { screen: Principal },
        Partido: { screen: Partido }
    },
    {
        initialRouteName: 'Principal',
        defaultNavigationOptions: {
            headerTintColor: '#FFF',
            headerStyle: {
                backgroundColor: '#3ba9a4',
                borderWidth: 0,
                borderBottomColor: 'white'
            }
        }
    }
);

const AppContainer = createAppContainer(App);

export default AppContainer;