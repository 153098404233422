import React from "react";
import {
    StyleSheet,
    TouchableOpacity,
    View,
    FlatList,
    Dimensions,
    Image,
    Text,
    Platform,
    Alert,
    ActivityIndicator,
    Modal,
    Share,
    Linking,
    Picker
} from "react-native";
import { AppLoading, Updates } from "expo";
import { Asset } from "expo-asset";
import Constants from "expo-constants";
import * as Font from "expo-font";
import * as Localization from "expo-localization";
import * as SecureStore from 'expo-secure-store';
import * as Network from 'expo-network';
import { MaterialIcons, Feather } from "@expo/vector-icons";
import CustomModal from "react-native-modal";
import SettingsList from "react-native-settings-list";
import Toast from "react-native-root-toast";
import Slider from 'react-native-slider';
import { NavigationEvents } from 'react-navigation';
import { partidos } from "../Utility/Utilidad";
import { enviarInfo } from "../Utility/Api";

export default class Principal extends React.PureComponent {
    posicionPartido = 0;

    constructor(props) {
        super(props);

        let numColumns = 2;

        if (Platform.OS == "web") {
            numColumns = 4;
        }

        this.state = {
            width: this.getWidth(),
            numColumns: numColumns,
            ordenPartidos: "congreso desc",
            modalVisible: false,
            floatingButton: true,
            settings: false,
            actualizarFlatlist: false,
            isAppReady: false,
        };
        enviarInfo("app", "app");
    }

    componentDidMount() {
        if (Platform.OS != "web") {
            this.updateNumColumns();
            this.updateOrdenPartidos();

            this.props.navigation.setParams({
                headerRight: () => (
                    <TouchableOpacity
                        style={{ paddingHorizontal: 15 }}
                        onPress={() => { this.showSettings(true) }} >
                        <MaterialIcons name={"settings"} size={30} color="#fff" />
                    </TouchableOpacity>
                )
            });

            this.checkUpdates();
        }
    }

    async _cacheResourcesAsync() {
        let images = [
            require('../Images/web.png'),
            require('../Images/facebook.png'),
            require('../Images/twitter.png'),
            require('../Images/whatsapp.png'),
            require('../Images/instagram.png'),
            require('../Images/update.png'),
            require('../Images/columns.png'),
            require('../Images/compartir.png'),
            require('../Images/privacy.png'),
            require('../Images/information.png'),
            require('../Images/playstore.png'),
        ];

        for (let i = 0; i < partidos.length; i++) {
            images.push(partidos[i].fotoPartido);
            images.push(partidos[i].fotoPresidente);
        }

        const fonts = [
            Feather.font,
            MaterialIcons.font,
        ];

        const cacheImages = images.map((image) => {
            return Asset.fromModule(image).downloadAsync();
        });

        const cacheFonts = fonts.map((font) => {
            return Font.loadAsync(font);
        });

        await Promise.all([...cacheImages, ...cacheFonts]);
    }

    checkUpdates = async () => {
        try {
            const update = await Updates.checkForUpdateAsync();
            if (update.isAvailable) {
                await Updates.fetchUpdateAsync();
                setTimeout(() => {
                    Alert.alert(
                        'Actualización disponible\n\n',
                        '¿Quieres actualizar ahora?.\n',
                        [
                            { text: 'Más tarde', onPress: () => { } },
                            { text: 'OK', onPress: () => { Updates.reloadFromCache(); } }
                        ],
                        { cancelable: true }
                    )
                }, 500);
            }
        } catch (error) { }
    }

    getWidth = () => {
        if (Dimensions.get('window').width > Dimensions.get('window').height) {
            return Dimensions.get('window').height;
        } else {
            return Dimensions.get('window').width;
        }
    }

    showSettings = (visible) => {
        this.setState({
            settings: visible
        }, () => {
            if (visible) {
                setTimeout(() => {
                    this.actualizarFlatlist();
                }, 500);
            }
        });
    }

    _onClick = (item, index) => {
        this.posicionPartido = Math.floor(index / this.state.numColumns);
        this.props.navigation.navigate("Partido", { partido: item });
    }

    renderItem = ({ item, index }) => {
        if (item.empty === true) {
            return (<View key={item.key} style={[styles.item, styles.itemInvisible]} />);
        }
        return (
            <ItemPartido
                item={item}
                index={index}
                _onClick={this._onClick}
                height={this.state.width / this.state.numColumns} />
        );
    }

    onLayout = () => { //Cuando se rota la pantalla, para actualizar el ancho
        this.setState({ width: this.getWidth() });
    }

    formatData = (partidos, numColumns) => {
        let numeroElementosUltimaFila = partidos.length % numColumns;
        let contador = partidos.length;
        let partidosTemporal = [...partidos];
        partidosTemporal.sort(this.ordenarPartidos);
        if (numeroElementosUltimaFila !== 0 && numeroElementosUltimaFila !== numColumns) {
            for (let i = 1; i <= numColumns - numeroElementosUltimaFila; i++) {
                partidosTemporal.push({ key: `${contador + i}`, empty: true });
            }
        }
        return partidosTemporal;
    }

    ordenarPartidos = (partido1, partido2) => {
        if (this.state.ordenPartidos.startsWith("alfabeto")) {
            if (this.state.ordenPartidos.endsWith("asc")) {
                return partido1.nombre > partido2.nombre;
            } else {
                return partido1.nombre < partido2.nombre;
            }
        } else if (this.state.ordenPartidos.startsWith("año")) {
            if (this.state.ordenPartidos.endsWith("asc")) {
                return partido1.año > partido2.año;
            } else {
                return partido1.año < partido2.año;
            }
        } else {
            let escaños1;
            let escaños2;

            if (partido1 && partido1.informacionPartido && partido1.informacionPartido["Escaños actuales (Congreso):"]) {
                escaños1 = partido1.informacionPartido["Escaños actuales (Congreso):"];
            }
            if (partido2 && partido2.informacionPartido && partido2.informacionPartido["Escaños actuales (Congreso):"]) {
                escaños2 = partido2.informacionPartido["Escaños actuales (Congreso):"];
            }

            if (!escaños1 || escaños1 == "") {
                escaños1 = 0;
            }
            if (!escaños2 || escaños2 == "") {
                escaños2 = 0;
            }

            escaños1 = parseInt(escaños1);
            if (isNaN(escaños1)) {
                escaños1 = 0;
            }
            escaños2 = parseInt(escaños2);
            if (isNaN(escaños2)) {
                escaños2 = 0;
            }

            if (this.state.ordenPartidos.endsWith("asc")) {
                return escaños1 - escaños2;
            } else {
                return escaños2 - escaños1;
            }
        }
    }

    storeNumColumns = async (numColumns) => {
        try {
            await SecureStore.setItemAsync("numColumns", "".concat(numColumns));
        } catch (error) {
            console.warn("ERROR storeNumColumns: " + error)
        }
    }

    updateNumColumns = async () => {
        try {
            const numColumns = await SecureStore.getItemAsync("numColumns");
            if (numColumns !== null && numColumns != this.state.numColumns) {
                this.posicionPartido = 0;
                this.setState({
                    numColumns: Number(numColumns)
                });
            }
        } catch (error) {
            console.warn("ERROR updateNumColumns: " + error)
        }
    }

    storeOrdenPartidos = async (ordenPartidos) => {
        try {
            await SecureStore.setItemAsync("ordenPartidos", ordenPartidos);
        } catch (error) {
            console.warn("ERROR storeOrdenPartidos: " + error)
        }
    }

    updateOrdenPartidos = async () => {
        try {
            const ordenPartidos = await SecureStore.getItemAsync("ordenPartidos");
            if (ordenPartidos !== null && ordenPartidos != this.state.ordenPartidos) {
                this.posicionPartido = 0;
                this.setState({
                    ordenPartidos: ordenPartidos
                });
            }
        } catch (error) {
            console.warn("ERROR updateOrdenPartidos: " + error)
        }
    }

    cambiarColumnas = (visible) => {
        this.setState({ modalVisible: visible })
    }

    actualizarFlatlist = () => {
        this.setState({ actualizarFlatlist: !this.state.actualizarFlatlist });
    }

    render() {
        if (!this.state.isAppReady) {
            return (
                <AppLoading
                    startAsync={this._cacheResourcesAsync}
                    onFinish={() => this.setState({ isAppReady: true })}
                    onError={console.warn}
                />
            );
        }

        const flatListKey = "".concat(this.state.numColumns).concat(this.state.actualizarFlatlist);
        return (
            <View style={{ flex: 1 }}>
                <NavigationEvents
                    //onWillBlur={payload => { }}
                    //onDidFocus={payload => { }}
                    //onDidBlur={payload => console.warn('did blur', payload)}
                    onWillFocus={payload => { this.actualizarFlatlist(); }}
                />
                {this.state.modalVisible ? <CustomModal
                    style={styles.bottomModal}
                    isVisible={this.state.modalVisible}
                    onBackButtonPress={() => { this.cambiarColumnas(false); }}
                    onBackdropPress={() => { this.cambiarColumnas(false); }}
                    onModalHide={() => {
                        this.storeNumColumns(this.state.numColumns);
                        this.storeOrdenPartidos(this.state.ordenPartidos);
                    }}>
                    <View style={{ height: "auto", backgroundColor: "#FFF", alignItems: "center", paddingVertical: 20 /*justifyContent: "center"*/ }}>
                        <Text style={{ color: "#000", width: this.state.width, textAlign: "center", marginBottom: 15 }}>
                            Mostrando {this.state.numColumns} columnas de partidos
                        </Text>
                        <Slider
                            style={{ width: "75%", marginBottom: 25 }}
                            step={1}
                            minimumValue={2}
                            maximumValue={6}
                            value={this.state.numColumns}
                            onValueChange={val => {
                                this.posicionPartido = 0;
                                this.setState({ numColumns: val });
                            }}
                            minimumTrackTintColor='#3ba9a4'
                            maximumTrackTintColor='#d3d3d3'
                            thumbTintColor='#2e827e'
                        />
                        <Text style={{ color: "#000", width: this.state.width, textAlign: "center", marginBottom: 15 }}>
                            Ordenar partidos por:
                        </Text>
                        <Picker
                            selectedValue={this.state.ordenPartidos}
                            style={{ height: 50, width: "55%", marginBottom: 30, justifyContent: 'center', }}
                            onValueChange={(itemValue, itemIndex) => {
                                this.posicionPartido = 0;
                                this.setState({ ordenPartidos: itemValue });
                            }}>
                            <Picker.Item label="Escaños Congreso ↑" value="congreso asc" />
                            <Picker.Item label="Escaños Congreso ↓" value="congreso desc" />
                            <Picker.Item label="Alfabéticamente ↑" value="alfabeto asc" />
                            <Picker.Item label="Alfabéticamente ↓" value="alfabeto desc" />
                            <Picker.Item label="Año Fundación ↑" value="año asc" />
                            <Picker.Item label="Año Fundación ↓" value="año desc" />
                        </Picker>
                    </View>
                </CustomModal> : <View></View>}
                <FlatList
                    key={flatListKey}
                    ref={(ref) => { this.flatList = ref; }}
                    style={styles.container}
                    data={this.formatData(partidos, this.state.numColumns)}
                    renderItem={this.renderItem}
                    numColumns={this.state.numColumns}
                    onLayout={this.onLayout}
                    initialScrollIndex={this.posicionPartido}
                    getItemLayout={(data, index) => {
                        const paddings = 25;
                        const ITEM_HEIGHT = (this.state.width / this.state.numColumns) + paddings;
                        return { length: ITEM_HEIGHT, offset: ITEM_HEIGHT * index, index };
                    }}
                /*onScrollBeginDrag={() => { this.setState({ floatingButton: false }); }}
                onMomentumScrollBegin={() => { this.setState({ floatingButton: false }); }}
                onScrollEndDrag={() => { this.setState({ floatingButton: true }); }}
                onMomentumScrollEnd={() => { this.setState({ floatingButton: true }); }} */
                />
                {/*<FloatingAction
                    actions={actions}
                    overrideWithAction={true}
                    iconHeight={30}
                    iconWidth={30}
                    onPressItem={(name) => { this.cambiarColumnas(true); }}
                    visible={this.state.floatingButton}
                    color={"#3ba9a4"}
                />*/}
                {this.state.showSettings ? <Settings
                    settings={this.state.settings}
                    showSettings={this.showSettings}
                    cambiarColumnas={this.cambiarColumnas}
                /> : <View></View>}
            </View>
        );
    }

    static navigationOptions = ({ navigation }) => {
        if (Platform.OS != "web") {
            var auxHeaderRight = () => (
                <TouchableOpacity
                    style={{ paddingHorizontal: 15 }} >
                    <MaterialIcons name={"settings"} size={30} color="#fff" />
                </TouchableOpacity>
            );
        } else{
            var headerTitleAlign = "center";
        }
        return {
            headerTitle: 'Partidos Políticos España',
            headerRight: navigation.getParam("headerRight", auxHeaderRight),
            headerTitleAlign: headerTitleAlign
        }
    }
}

class ItemPartido extends React.PureComponent {
    _onPress = () => {
        this.props._onClick(this.props.item, this.props.index);
    }

    render() {
        return (
            <TouchableOpacity
                key={this.props.item.key}
                style={[styles.item, { height: this.props.height }]}
                onPress={() => { this._onPress() }}>
                <Image
                    key={this.props.item.key}
                    source={this.props.item.fotoPartido}
                    style={{ width: "100%", height: "100%" }}
                    resizeMode={"contain"}
                    resizeMethod={"resize"}>
                </Image>
            </TouchableOpacity>
        );
    }
}

class Settings extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            updating: false
        }
    }

    updateApp = () => {
        this.setState({ updating: true }, () => {
            setTimeout(async () => {
                try {
                    const update = await Updates.checkForUpdateAsync();
                    if (update.isAvailable) {
                        await Updates.fetchUpdateAsync();
                        Updates.reloadFromCache();
                    } else {
                        Toast.show('No hay actualizaciones disponibles, ya tienes la última versión!', {
                            duration: Toast.durations.LONG,
                            position: Toast.positions.BOTTOM,
                            shadow: true,
                            animation: true,
                            hideOnPress: true,
                            delay: 0,
                            backgroundColor: "#226360",
                            opacity: 0.9,
                            onShow: () => { },
                            onShown: () => { },
                            onHide: () => { },
                            onHidden: () => { }
                        });
                    }
                } catch (error) {
                    Toast.show('Ha ocurrido un error buscando actualizaciones, inténtelo de nuevo', {
                        duration: Toast.durations.LONG,
                        position: Toast.positions.BOTTOM,
                        shadow: true,
                        animation: true,
                        hideOnPress: true,
                        delay: 0,
                        backgroundColor: "#226360",
                        opacity: 0.9,
                        onShow: () => { },
                        onShown: () => { },
                        onHide: () => { },
                        onHidden: () => { }
                    });
                }
                this.setState({ updating: false });
            }, 1000);
        });
    }

    render() {
        const marginTop = (Platform.OS === 'android') ? 5 : 30;
        return (
            <View>
                <Modal
                    animationType="fade"
                    transparent={false}
                    visible={this.props.settings}
                    onRequestClose={() => { this.props.showSettings(false); }}>
                    <View style={{ backgroundColor: '#FFF', flex: 1 }}>
                        <View style={{ borderBottomWidth: 1, backgroundColor: '#349994', borderColor: '#c8c7cc' }}>
                            <View style={{ flexDirection: 'row', marginTop: marginTop, marginBottom: 10 }}>
                                <TouchableOpacity
                                    style={{ zIndex: 999 }}
                                    onPress={() => { this.props.showSettings(false); }}>
                                    <MaterialIcons name={"chevron-left"} size={40} color="#fff" />
                                </TouchableOpacity>
                                <View style={{ position: 'absolute', width: '100%' }}>
                                    <Text style={{ marginTop: 8, fontWeight: 'bold', fontSize: 20, textAlign: 'center', color: '#fff' }}>Ajustes</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ backgroundColor: '#EFEFF4', flex: 1 }}>
                            <SettingsList borderColor='#c8c7cc' defaultItemSize={50}>
                                <SettingsList.Header headerStyle={{ marginTop: 15 }} />
                                <SettingsList.Item
                                    icon={<Image style={styles.imageStyle} source={require('../Images/update.png')} />}
                                    title='Actualizar aplicación'
                                    titleInfo='Actualizar'
                                    titleInfoStyle={styles.titleInfoStyle}
                                    onPress={() => {
                                        this.props.showSettings(false);
                                        this.updateApp();
                                    }}
                                />
                                <SettingsList.Item
                                    icon={<Image style={styles.imageStyle} source={require('../Images/columns.png')} />}
                                    title='Organización Visual'
                                    titleInfo='Cambiar'
                                    titleInfoStyle={styles.titleInfoStyle}
                                    onPress={() => {
                                        this.props.showSettings(false);
                                        this.props.cambiarColumnas(true);
                                    }}
                                />
                                <SettingsList.Item
                                    icon={<Image style={styles.imageStyle} source={require('../Images/playstore.png')} />}
                                    title='Ver en Play Store'
                                    titleInfo='Abrir'
                                    titleInfoStyle={styles.titleInfoStyle}
                                    onPress={() => {
                                        let url = "market://details?id=com.himnospartidospoliticos";
                                        Linking.canOpenURL(url).then((supported) => {
                                            if (!supported) {
                                                url = "https://play.google.com/store/apps/details?id=com.himnospartidospoliticos";
                                            }
                                            return Linking.openURL(url);
                                        });
                                    }}
                                />
                                <SettingsList.Item
                                    icon={<Image style={styles.imageStyle} source={require('../Images/compartir.png')} />}
                                    title='Compartir aplicación'
                                    titleInfo='Compartir'
                                    titleInfoStyle={styles.titleInfoStyle}
                                    onPress={() => {
                                        const mensaje = "Conoce la informacion de nuestros partidos politicos en:\n https://play.google.com/store/apps/details?id=com.himnospartidospoliticos";
                                        Share.share({
                                            title: "Partidos Políticos España",
                                            message: mensaje
                                        });
                                    }}
                                />
                                <SettingsList.Header headerStyle={{ marginTop: 15 }} />
                                <SettingsList.Item
                                    icon={<Image style={styles.imageStyle} source={require('../Images/privacy.png')} />}
                                    title='Política de privacidad'
                                    onPress={async () => {
                                        Linking.openURL("http://www.larryrider.es/apps/partidos/privacy/privacy_policy.html");
                                    }}
                                />
                                <SettingsList.Item
                                    icon={<Image style={styles.imageStyle} source={require('../Images/information.png')} />}
                                    title='Información del sistema'
                                    onPress={async () => {
                                        let ip;
                                        try { ip = '\nIp: ' + await Network.getIpAddressAsync(); } catch (error) { };
                                        const informacionSistema = 'Expo: ' + Constants.expoVersion + '\nId: ' + Constants.installationId +
                                            '\nDispositivo: ' + Constants.deviceName + '\nAño: ' + Constants.deviceYearClass +
                                            '\nPlataforma: ' + (Platform.OS === 'ios' ? 'iOS' : 'Android') +
                                            '\nVersión Sistema: ' + parseInt(Platform.Version, 10) +
                                            (Platform.OS === 'ios' ? '\nModelo: ' + Constants.platform.ios.model : '') +
                                            (Platform.OS === 'ios' ? '\nPaís: ' + await Localization.region : '') +
                                            (ip ? ip : '') +
                                            '\n\nVersión App: ' + Constants.manifest.version;
                                        Alert.alert(
                                            'Información del sistema',
                                            informacionSistema,
                                            [
                                                {
                                                    text: 'Compartir', onPress: () => {
                                                        Share.share({
                                                            title: "Información del telefono",
                                                            message: informacionSistema
                                                        });
                                                    }
                                                },
                                                { text: 'OK', onPress: () => { } }
                                            ],
                                            { cancelable: true }
                                        );
                                    }}
                                />
                            </SettingsList>
                        </View>
                    </View>
                </Modal>
                <Modal
                    animationType="fade"
                    transparent={false}
                    visible={this.state.updating}
                    onRequestClose={() => { }}>
                    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 20, fontWeight: '200', color: '#349994', marginBottom: 20 }}>
                            Actualizando...
                        </Text>
                        <ActivityIndicator size="large" color="#349994" />
                    </View>
                </Modal>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#FFF',
        paddingVertical: 10,
        paddingBottom: 15
    },
    item: {
        backgroundColor: '#FFF', //'#4D243D',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        margin: 10,
    },
    itemInvisible: {
        backgroundColor: 'transparent', //transparent
    },
    bottomModal: {
        justifyContent: "flex-end",
        margin: 0
    },
    imageStyle: {
        marginLeft: 15,
        alignSelf: 'center',
        height: 30,
        width: 30
    },
    titleInfoStyle: {
        fontSize: 16,
        color: '#8e8e93'
    }
});