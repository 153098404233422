import React from "react";
import {
    View,
    Text,
    Image,
    StyleSheet,
    ScrollView,
    TouchableOpacity,
    Dimensions,
    Linking,
    Modal,
    Alert,
    Platform
} from "react-native";
import { MaterialIcons, Feather } from "@expo/vector-icons";
import { Asset } from "expo-asset";
import { activateKeepAwake, deactivateKeepAwake } from 'expo-keep-awake';
import HimnosPartido from "./HimnosPartido.js";
import { enviarInfo } from "../Utility/Api";

export default class Partido extends React.PureComponent {
    constructor(props) {
        super(props);
        const { navigation } = props;
        const partido = navigation.getParam('partido', {});
        this.state = {
            partido: partido,
            width: this.getWidth(),
            fotoPartidoFullscreen: false,
            fotoPresidenteFullscreen: false
        };
        enviarInfo(partido.siglas, "perfil");
    }

    componentDidMount() {
        if (Platform.OS != "web") {
            this.props.navigation.setParams({
                headerRight: () => (
                    <TouchableOpacity
                        style={{ paddingHorizontal: 18 }}
                        onPress={() => { this.showHelp(); }}>
                        <Feather name={"help-circle"} size={30} color="#fff" />
                    </TouchableOpacity>
                )
            });
        }
        const partido = this.props.navigation.getParam('partido', {});
        if (partido.himno) {
            Asset.fromModule(
                partido.himno
            ).downloadAsync();
        }
        if (partido.himnoCorto) {
            Asset.fromModule(
                partido.himnoCorto
            ).downloadAsync();
        }
    }

    showHelp = () => {
        const mensaje = '\nFuente de información:\n' +
            'Todos los datos aquí mostrados se han recolectado de Webs oficiales o Wikipedia®. En ningún caso nos pertenece a nosotros.\n\n\n' +
            'Modo meeting:\n' +
            'Si pulsas sobre el logo del partido, éste se pone a pantalla completa (pensado para apoyar a tu partido en los meetings).\n\n\n' +
            'Sugerencias y errores son bien recibidos en: larryriderandroid@gmail.com\n';
        Alert.alert(
            'Información',
            mensaje,
            [
                //{ text: 'Enviar Email', onPress: () => { Linking.openURL("mailto:larryriderandroid@gmail.com"); } },
                { text: 'OK', onPress: () => { } }
            ],
            { cancelable: true }
        );
    }

    getWidth = () => {
        if (Dimensions.get('window').width > Dimensions.get('window').height) {
            return Dimensions.get('window').height;
        } else {
            return Dimensions.get('window').width;
        }
    }

    onLayout = () => { //Cuando se rota la pantalla, para actualizar el ancho
        this.setState({
            width: this.getWidth()
        });
    }

    getRedesSociales = () => {
        const redesPartido = this.state.partido.redesSociales;
        let redes = [];
        for (let redSocial in redesPartido) {
            if (redesPartido[redSocial] && redesPartido[redSocial] != "") {
                if (redSocial == "web") {
                    redes.push(
                        <TouchableOpacity
                            key={redSocial}
                            style={[styles.foto, { height: this.state.width / 5, width: this.state.width / 5 }]}
                            target="_blank"
                            onPress={() => {
                                Linking.openURL("http://www." + redesPartido[redSocial]);
                                enviarInfo(this.state.partido.siglas, redSocial);
                            }}>
                            <Image source={require("../Images/web.png")} style={{ width: "100%", height: "100%" }} resizeMode={"contain"} resizeMethod={"resize"} />
                        </TouchableOpacity>
                    );
                } else if (redSocial == "whatsapp") {
                    redes.push(
                        <TouchableOpacity
                            key={redSocial}
                            style={[styles.foto, { height: this.state.width / 5, width: this.state.width / 5 }]}
                            onPress={() => {
                                let url = "https://api.whatsapp.com/send?phone=" + redesPartido[redSocial];
                                if (redesPartido[redSocial].includes("http")) {
                                    url = redesPartido[redSocial];
                                }
                                Linking.openURL(url);
                                enviarInfo(this.state.partido.siglas, redSocial);
                            }}>
                            <Image source={require("../Images/whatsapp.png")} style={{ width: "100%", height: "100%" }} resizeMode={"contain"} resizeMethod={"resize"}></Image>
                        </TouchableOpacity>
                    );
                } else if (redSocial == "facebook") {
                    redes.push(
                        <TouchableOpacity
                            key={redSocial}
                            style={[styles.foto, { height: this.state.width / 5, width: this.state.width / 5 }]}
                            onPress={() => {
                                let url = "fb://facewebmodal/f?href=" + "https://www.facebook.com/" + redesPartido[redSocial];
                                Linking.canOpenURL(url).then((supported) => {
                                    if (!supported || Platform.OS == "web") {
                                        url = "https://www.facebook.com/" + redesPartido[redSocial];
                                    }
                                    return Linking.openURL(url);
                                });
                                enviarInfo(this.state.partido.siglas, redSocial);
                            }}>
                            <Image source={require("../Images/facebook.png")} style={{ width: "100%", height: "100%" }} resizeMode={"contain"} resizeMethod={"resize"}></Image>
                        </TouchableOpacity>
                    );
                } else if (redSocial == "twitter") {
                    redes.push(
                        <TouchableOpacity
                            key={redSocial}
                            style={[styles.foto, { height: this.state.width / 5, width: this.state.width / 5 }]}
                            onPress={() => {
                                let url = "twitter://user?user_id=" + redesPartido[redSocial];
                                Linking.canOpenURL(url).then((supported) => {
                                    if (!supported || Platform.OS == "web") {
                                        url = "https://twitter.com/intent/user?user_id=" + redesPartido[redSocial];
                                    }
                                    return Linking.openURL(url);
                                });
                                enviarInfo(this.state.partido.siglas, redSocial);
                            }}>
                            <Image source={require("../Images/twitter.png")} style={{ width: "100%", height: "100%" }} resizeMode={"contain"} resizeMethod={"resize"}></Image>
                        </TouchableOpacity>
                    );
                } else if (redSocial == "instagram") {
                    redes.push(
                        <TouchableOpacity
                            key={redSocial}
                            style={[styles.foto, { height: this.state.width / 5, width: this.state.width / 5 }]}
                            onPress={() => {
                                let url = "http://instagram.com/_u/" + redesPartido[redSocial];
                                Linking.canOpenURL(url).then((supported) => {
                                    if (!supported || Platform.OS == "web") {
                                        url = "http://instagram.com/" + redesPartido[redSocial];
                                    }
                                    return Linking.openURL(url);
                                });
                                enviarInfo(this.state.partido.siglas, redSocial);
                            }}>
                            <Image source={require("../Images/instagram.png")} style={{ width: "100%", height: "100%" }} resizeMode={"contain"} resizeMethod={"resize"}></Image>
                        </TouchableOpacity>
                    );
                }
            }
        }
        return redes;
    }

    getInformacion = () => {
        let informacion = [];
        const informacionPartido = this.state.partido.informacionPartido;
        let first = true;
        for (let titulo in informacionPartido) {
            let intro = "";
            if (!first) {
                intro = "\n";
            } else {
                first = false;
            }
            if (titulo == "Más información:") {
                let informaciones = [];
                for (let masInfo in informacionPartido[titulo]) {
                    informaciones.push(
                        <Text
                            key={masInfo}
                            onPress={() => {
                                Linking.openURL("http://www." + informacionPartido[titulo][masInfo]);
                                enviarInfo(this.state.partido.siglas, "programa");
                            }}>
                            <Text style={[styles.descripcion, {
                                color: "#07C",
                                textDecorationLine: "underline"
                            }]}>
                                {masInfo}
                            </Text>
                            {" "}
                            <Feather name={"link"} size={12} color="#07C" />
                            {"\n"}
                        </Text>
                    );
                }
                informacion.push(
                    <Text key={titulo}>
                        <Text style={styles.titulo}>
                            {intro}{titulo}{"\n"}
                        </Text>
                        <Text style={styles.descripcion}>
                            {informaciones}
                        </Text>
                    </Text>
                );
            } else {
                informacion.push(
                    <Text key={titulo}>
                        <Text style={styles.titulo}>
                            {intro}{titulo}{"\n"}
                        </Text>
                        <Text style={styles.descripcion}>
                            {informacionPartido[titulo]}{"\n"}
                        </Text>
                    </Text>
                );
            }
        }
        return informacion;
    }

    mostrarFotoPartidoFullscreen = (visible) => {
        this.setState({
            fotoPresidenteFullscreen: false,
            fotoPartidoFullscreen: visible
        }, () => {
            this.mantenerPantalla(visible);
        });
    }

    mostrarFotoPresidenteFullscreen = (visible) => {
        this.setState({
            fotoPresidenteFullscreen: visible,
            fotoPartidoFullscreen: false
        }, () => {
            this.mantenerPantalla(visible);
        });
    }

    render() {
        const redesSociales = this.getRedesSociales();
        const informacion = this.getInformacion();
        return (
            <View style={{ flex: 1, backgroundColor: '#FFF', }}>
                <ScrollView
                    style={{ flex: 1 }}
                    onLayout={this.onLayout}
                    showsVerticalScrollIndicator={true}>
                    <Text style={{ width: "100%", marginVertical: 10, textAlign: "center", fontSize: 20, fontWeight: "bold", color: "#349994" }}>
                        {this.state.partido.nombre}
                    </Text>
                    <View style={styles.fila}>
                        <View style={styles.columna}>
                            <TouchableOpacity
                                style={[styles.foto, { height: this.state.width / 2, width: this.state.width / 2 }]}
                                onPress={() => { this.mostrarFotoPartidoFullscreen(true); }}>
                                <Image source={this.state.partido.fotoPartido} style={{ width: "100%", height: "100%" }} resizeMode={"contain"} resizeMethod={"resize"}></Image>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.columna}>
                            <TouchableOpacity
                                style={[styles.foto, { height: this.state.width / 2, width: this.state.width / 2 }]}
                                onPress={() => { this.mostrarFotoPresidenteFullscreen(true); }}>
                                <Image source={this.state.partido.fotoPresidente} style={{ width: "100%", height: "100%" }} resizeMode={"contain"} resizeMethod={"resize"}></Image>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.horizontalLine} />
                    <HimnosPartido
                        partido={this.state.partido} >
                    </HimnosPartido>
                    <Text style={{ width: "100%", textAlign: "center", fontSize: 19, fontWeight: "bold" }}>
                        Información (obtenida de Wikipedia®)
                    </Text>
                    <View style={styles.fila}>
                        <View style={[styles.columna, { paddingLeft: 18, width: "100%", alignItems: 'flex-start', justifyContent: 'flex-start' }]}>
                            <Text style={{ textAlign: "justify" }}>
                                {informacion}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.horizontalLine} />
                    <Text style={{ width: "100%", textAlign: "center", fontSize: 19, fontWeight: "bold" }}>
                        Redes sociales
                    </Text>
                    <View style={styles.fila}>
                        <View style={[styles.columna, { width: "100%", flexDirection: "row" }]}>
                            {redesSociales}
                        </View>
                    </View>
                </ScrollView>
                <Modal
                    animationType="fade"
                    transparent={false}
                    visible={this.state.fotoPartidoFullscreen}
                    onRequestClose={() => { this.mostrarFotoPartidoFullscreen(false); }}>
                    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.partido.colorPartido }}>
                        <Image source={this.state.partido.fotoPartido} style={{ width: "100%", height: "100%" }} resizeMode={"contain"} resizeMethod={"resize"}></Image>
                    </View>
                    <TouchableOpacity
                        style={{
                            borderWidth: 1,
                            borderColor: '#349994',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 45,
                            height: 45,
                            backgroundColor: '#fff',
                            borderRadius: 100,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            margin: 10,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        onPress={() => { this.mostrarFotoPartidoFullscreen(false); }}>
                        <MaterialIcons name={"chevron-left"} size={40} color="#349994" />
                    </TouchableOpacity>
                </Modal>
                <Modal
                    animationType="fade"
                    transparent={false}
                    visible={this.state.fotoPresidenteFullscreen}
                    onRequestClose={() => { this.mostrarFotoPresidenteFullscreen(false); }}>
                    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFF" }}>
                        <Image source={this.state.partido.fotoPresidente} style={{ width: "100%", height: "100%" }} resizeMode={"contain"} resizeMethod={"resize"}></Image>
                    </View>
                    <TouchableOpacity
                        style={{
                            borderWidth: 1,
                            borderColor: '#349994',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 45,
                            height: 45,
                            backgroundColor: '#fff',
                            borderRadius: 100,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            margin: 10,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        onPress={() => { this.mostrarFotoPresidenteFullscreen(false); }}>
                        <MaterialIcons name={"chevron-left"} size={40} color="#349994" />
                    </TouchableOpacity>
                </Modal>
            </View>
        );
    }

    mantenerPantalla = (encendida) => {
        if (encendida) {
            activateKeepAwake();
        } else {
            deactivateKeepAwake();
        }
    }

    static navigationOptions = ({ navigation }) => {
        if (Platform.OS != "web") {
            var auxHeaderRight = () => (
                <TouchableOpacity style={{ paddingHorizontal: 18 }}>
                    <Feather name={"help-circle"} size={30} color="#fff" />
                </TouchableOpacity>
            );
        }
        return {
            headerTitle: 'Ficha del Partido', //navigation.getParam("partido", { siglas: "" }).siglas,
            headerTitleStyle: {
                textAlign: 'left',
                width: '100%'
            },
            headerRight: navigation.getParam("headerRight", auxHeaderRight)
        };
    }
}

const styles = StyleSheet.create({
    horizontalLine: {
        borderBottomColor: "#349994",
        borderBottomWidth: 1,
        width: "100%",
        marginVertical: 10
    },
    fila: {
        flexDirection: "row",
        width: "100%",
        height: "auto"
    },
    filaSocial: {
        flexDirection: "row",
        width: "100%",
        height: "auto",
        flex: 1
    },
    columna: {
        width: "50%",
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10
    },
    foto: {
        backgroundColor: '#FFF', //'#4D243D',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        padding: 10
    },
    titulo: {
        fontWeight: "bold"
    }
});
