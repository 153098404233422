
const partidos = [
    {
        key: '1',
        nombre: "Partido Popular",
        siglas: "PP",
        fotoPartido: require('../Images/pp.png'),
        fotoPresidente: require('../Images/pp_presidente.png'),
        himno: require('../Himnos/himno_pp.mp3'),
        himnoCorto: require('../Himnos/himno_pp_corto.mp3'),
        colorPartido: "#FFFFFF",
        año: "1989",
        redesSociales: {
            web: "pp.es",
            facebook: "pp",
            twitter: "20509689",
            whatsapp: "34682631721",
            instagram: "partidopopular"
        },
        informacionPartido: {
            "Presidente del partido:": "Pablo Casado",
            "Año de fundación:": "20 de enero de 1989",
            "Escaños actuales (Congreso):": "88",
            "Sede:": "Calle de Génova, 13, 28004 (Madrid)",
            "Ideología:": "Conservador-liberal, Democracia cristiana, Nacionalismo español, Europeismo, Humanismo cristiano",
            "Posición:": "Centroderecha, Derecha",
            "Más información:" : {
                "Programa Electoral": "pp.es/conocenos/programas",
            }
        }
    },
    {
        key: '2',
        nombre: "Partido Socialista Obrero Español",
        siglas: "PSOE",
        fotoPartido: require('../Images/psoe.png'),
        fotoPresidente: require('../Images/psoe_presidente.png'),
        himno: require('../Himnos/himno_psoe.mp3'),
        himnoCorto: require('../Himnos/himno_psoe_corto.mp3'),
        colorPartido: "#e30613",
        año: "1879",
        redesSociales: {
            web: "psoe.es",
            facebook: "psoe",
            twitter: "50982086",
            whatsapp: "",
            instagram: "psoe"
        },
        informacionPartido: {
            "Secretario general del partido:": "Pedro Sánchez",
            "Año de fundación:": "2 de mayo de 1879",
            "Escaños actuales (Congreso):": "120",
            "Sede:": "Calle Ferraz, 70, 28008 Madrid",
            "Ideología:": "Socialdemocracia, Socioliberalismo, Europeismo, Federalismo asimétrico, Feminismo, A favor del Modelo de las Autonomías, Ecologismo, Republicanismo",
            "Posición:": "Centroizquierda",
            "Más información:" : {
                "Programa Electoral": "psoe.es/propuestas/",
            }
        }
    },
    {
        key: '3',
        nombre: "Podemos",
        siglas: "PODEMOS",
        fotoPartido: require('../Images/podemos.png'),
        fotoPresidente: require('../Images/podemos_presidente.png'),
        himno: require('../Himnos/himno_podemos.mp3'),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#FFFFFF",
        año: "2014",
        redesSociales: {
            web: "podemos.info",
            facebook: "ahorapodemos",
            twitter: "2288138575",
            whatsapp: "https://w.podemos.info/",
            instagram: "ahorapodemos"
        },
        informacionPartido: {
            "Secretario general del partido:": "Pablo Iglesias",
            "Año de fundación:": "17 de enero de 2014",
            "Escaños actuales (Congreso):": "35",
            "Sede:": "Calle Zurita, 21, 28012 Madrid",
            "Ideología:": "Igualitarismo, Feminismo, Socialdemocracia, Republicanismo, Comunismo, Democracia participativa",
            "Posición:": "Izquierda",
            "Más información:" : {
                "Programa Electoral": "podemos.info/programa/",
            }
        }
    },
    {
        key: '4',
        nombre: "Ciudadanos",
        siglas: "CIUDADANOS",
        fotoPartido: require('../Images/ciudadanos.png'),
        fotoPresidente: require('../Images/ciudadanos_presidente.png'),
        //himno: require('../Himnos/himno_.mp3),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#ffffff",
        año: "2006",
        redesSociales: {
            web: "ciudadanos-cs.org",
            facebook: "Cs.Ciudadanos",
            twitter: "19028805",
            whatsapp: "",
            instagram: "ciudadanos"
        },
        informacionPartido: {
            "Presidente del partido:": "Albert Rivera",
            "Año de fundación:": "1 de junio de 2006",
            "Escaños actuales (Congreso):": "10",
            "Sede:": "Calle de Alcalá, 253, 28027, Madrid​",
            "Ideología:": "Socialdemocracia, Constitucionalismo, Liberalismo, Laicismo, Antiindependentismo, Federalismo, Progresismo",
            "Posición:": "Centro, Centroderecha, Centroizquierda",
            "Más información:" : {
                "Programa Electoral": "ciudadanos-cs.org/programa-electoral",
            }
        }
    },
    {
        key: '5',
        nombre: "Esquerra Republicana de Cataluña",
        siglas: "ERC",
        fotoPartido: require('../Images/erc.png'),
        fotoPresidente: require('../Images/erc_presidente.png'),
        himno: require('../Himnos/himno_erc.mp3'),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#fbc259",
        año: "1931",
        redesSociales: {
            web: "esquerra.cat",
            facebook: "Esquerra.ERC",
            twitter: "84053338",
            whatsapp: "", //"34607624161",
            instagram: "esquerrarepublicana"
        },
        informacionPartido: {
            "Presidente del partido:": "Oriol Junqueras",
            "Año de fundación:": "19 de marzo de 1931",
            "Escaños actuales (Congreso):": "13",
            "Sede:": "Carrer de Calàbria, 166, 08015 Barcelona",
            "Ideología:": "Nacionalismo catalán, Independentismo catalán, Catalanismo, Socialismo democrático, Socialdemocracia, Republicanismo, Pancatalanismo",
            "Posición:": "Izquierda",
            "Más información:" : {
                "Programa Electoral": "esquerra.cat/ca/republica-que-farem/all",
            }
        }
    },
    {
        key: '6',
        nombre: "Izquierda Unida",
        siglas: "IU",
        fotoPartido: require('../Images/iu.png'),
        fotoPresidente: require('../Images/iu_presidente.png'),
        himno: require('../Himnos/himno_iu.mp3'),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#FFFFFF",
        año: "1986",
        redesSociales: {
            web: "izquierda-unida.org",
            facebook: "izquierda.unida",
            twitter: "14824411",
            whatsapp: "34680516199",
            instagram: "izquierda_unida"
        },
        informacionPartido: {
            "Coordinador federal:": "Alberto Garzón",
            "Año de fundación:": "29 de abril de 1986",
            "Escaños actuales (Congreso):": "5",
            "Sede:": "Calle Olimpo, 35, 28043 Madrid",
            "Ideología:": "Socialismo democrático, Ecosocialismo, Feminismo, Republicanismo, Federalismo, Anticapitalismo, Antiimperialismo, Antirracismo",
            "Posición:": "Izquierda, Extrema Izquierda",
            "Más información:" : {
                "Programa Electoral": "izquierdaunida.org/programa",
            }
        }
    },
    {
        key: '7',
        nombre: "Partido Demócrata Europeo Catalán",
        siglas: "PDC",
        fotoPartido: require('../Images/pdc.png'),
        fotoPresidente: require('../Images/pdc_presidente.png'),
        himno: require('../Himnos/himno_pdc.mp3'),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#0181ca",
        año: "2016",
        redesSociales: {
            web: "partitdemocrata.cat",
            facebook: "Pdemocratacat",
            twitter: "10242562",
            whatsapp: "",
            instagram: "pdemocratacat"
        },
        informacionPartido: {
            "Presidente del partido:": "David Bonvehí",
            "Año de fundación:": "29 de septiembre de 2016",
            "Escaños actuales (Congreso):": "7",
            "Sede:": "Calle de Provenza, 339, 08037 Barcelona",
            "Ideología:": "Nacionalismo catalán, Independentismo catalán, Catalanismo, Pancatalanismo demócrata, Europeismo, Humanismo, Republicanismo",
            "Posición:": "Centroderecha",
            "Más información:" : {
                "Programa Electoral": "partitdemocrata.cat/web/programes-electorals/",
            }
        }
    },
    {
        key: '8',
        nombre: "Partido Nacionalista Vasco",
        siglas: "PNV",
        fotoPartido: require('../Images/pnv.png'),
        fotoPresidente: require('../Images/pnv_presidente.png'),
        himno: require('../Himnos/himno_pnv.mp3'),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#ffffff",
        año: "1895",
        redesSociales: {
            web: "eaj-pnv.eus",
            facebook: "eajpnv",
            twitter: "83784273",
            whatsapp: "",
            instagram: "eajpnv"
        },
        informacionPartido: {
            "Presidente del partido:": "Andoni Ortuzar",
            "Año de fundación:": "31 de julio de 1895",
            "Escaños actuales (Congreso):": "6",
            "Sede:": "Calle Ibáñez de Bilbao, 16, 48001 Bilbao, Vizcaya",
            "Ideología:": "Nacionalismo vasco, Conservadurismo, Liberalismo económico, Nacionalismo liberal, Democracia cristiana, Soberanismo, Europeísmo",
            "Posición:": "Centroderecha, Centro",
            "Más información:" : {
                "Programa Electoral": "eaj-pnv.eus/documentos/",
            }
        }
    },
    {
        key: '9',
        nombre: "Compromis",
        siglas: "COMPROMIS",
        fotoPartido: require('../Images/compromis.png'),
        fotoPresidente: require('../Images/compromis_presidente.png'),
        //himno: require('../Himnos/himno_.mp3),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#ffffff",
        año: "2010",
        redesSociales: {
            web: "compromis.net",
            facebook: "coaliciocompromis",
            twitter: "148867925",
            whatsapp: "",
            instagram: "compromis_net"
        },
        informacionPartido: {
            "Portavoz del partido:": "Mónica Oltra",
            "Año de fundación:": "25 de enero de 2010",
            "Escaños actuales (Congreso):": "1",
            "Sede:": "Plaza del Pilar, 1, 46001 Valencia",
            "Ideología:": "Nacionalismo valenciano, Feminismo, Ecologismo, Ecosocialismo, Progresismo, Regionalismo, Federalismo",
            "Posición:": "Izquierda",
            "Más información:" : {
                "Programa Electoral": "compromis.net/info/transparencia/idees-i-valors/",
            }
        }
    },
    {
        key: '10',
        nombre: "Euskal Herria Bildu",
        siglas: "EHBILDU",
        fotoPartido: require('../Images/ehbildu.png'),
        fotoPresidente: require('../Images/ehbildu_presidente.png'),
        //himno: require('../Himnos/himno_.mp3),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#ffffff",
        año: "2012",
        redesSociales: {
            web: "ehbildu.eus",
            facebook: "ehbildu",
            twitter: "601887686",
            whatsapp: "",
            instagram: "eh_bildu"
        },
        informacionPartido: {
            "Líder del partido:": "Arnaldo Otegi",
            "Año de fundación:": "10 de junio de 2012",
            "Escaños actuales (Congreso):": "5",
            "Sede:": "Plaza del Castillo, 49, 18163 Pamplona, Navarra",
            "Ideología:": "Izquierda abertzale, Nacionalismo vasco, Socialismo, Independentismo, Soberanismo, Feminismo, Ecologismo, Progresismo, Anticapitalismo, Internacionalismo, Euskaldunización",
            "Posición:": "Izquierda, Extrema Izquierda",
            "Más información:" : {
                "Programa Electoral": "ehbildu.eus/es#eskaintza",
            }
        }
    },
    {
        key: '11',
        nombre: "Coalición Canaria",
        siglas: "COALICION CANARIA",
        fotoPartido: require('../Images/cc.png'),
        fotoPresidente: require('../Images/cc_presidente.png'),
        //himno: require('../Himnos/himno_cc.mp3'),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#ffffff",
        año: "1993",
        redesSociales: {
            web: "coalicioncanaria.org",
            facebook: "coalicion.canaria",
            twitter: "89543500",
            whatsapp: "34682643606",
            instagram: "coalicionc"
        },
        informacionPartido: {
            "Portavoz del partido:": "Ana Oramas",
            "Año de fundación:": "31 de marzo de 1993",
            "Escaños actuales (Congreso):": "1",
            "Sede:": "Buenos Aires 24, 35002 Las Palmas de Gran Canaria, Canarias",
            "Ideología:": "Nacionalismo canario, Progresismo, Constitucionalismo",
            "Posición:": "Centro, Centroderecha",
            "Más información:" : {
                "Programa Electoral": "coalicioncanaria.org/programas-electorales/",
            }
        }
    },
    {
        key: '12',
        nombre: "Unión Progreso y Democracia",
        siglas: "UPYD",
        fotoPartido: require('../Images/upyd.png'),
        fotoPresidente: require('../Images/upyd_presidente.png'),
        himno: require('../Himnos/himno_upyd.mp3'),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#da1d79",
        año: "2007",
        redesSociales: {
            web: "upyd.es",
            facebook: "Union.Progreso.y.Democracia",
            twitter: "146150851",
            whatsapp: "34602252446",
            instagram: "upyd_es"
        },
        informacionPartido: {
            "Portavoz del partido:": "Cristiano Brown",
            "Año de fundación:": "29 de septiembre de 2007",
            "Escaños actuales (Congreso):": "0",
            "Sede:": "Calle del Desengaño, 12, 28004 Madrid",
            "Ideología:": "Antinacionalismo, Centralismo, Centrismo radical, Federalismo europeo, Laicismo, Monarquismo, Patriotismo español, Progresismo, Reformismo, Socioliberalismo",
            "Posición:": "Centro",
            "Más información:" : {
                "Programa Electoral": "upyd.es/propuestas/",
            }
        }
    },
    {
        key: '13',
        nombre: "Bloque Nacionalista Gallego",
        siglas: "BNG",
        fotoPartido: require('../Images/bng.png'),
        fotoPresidente: require('../Images/bng_presidente.png'),
        himno: require('../Himnos/himno_bng.mp3'),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#ffffff",
        año: "1982",
        redesSociales: {
            web: "bng.gal",
            facebook: "obloque",
            twitter: "155544328",
            whatsapp: "34981555850",
            instagram: "o_bloque"
        },
        informacionPartido: {
            "Portavoz nacional:": "Ana Pontón",
            "Año de fundación:": "1982",
            "Escaños actuales (Congreso):": "1",
            "Sede:": "Avenida Rodríguez de Viguri, 16, Santiago de Compostela)",
            "Ideología:": "Nacionalismo gallego, Independentismo gallego, Socialismo, Comunismo, Democracia directa, Marxismo, Internacionalismo",
            "Posición:": "Izquierda",
            "Más información:" : {
                "Programa Electoral": "bng.gal/estaticas/programas-eleitorais",
            }
        }
    },
    {
        key: '14',
        nombre: "Candidatura de Unidad Popular",
        siglas: "CUP",
        fotoPartido: require('../Images/cup.png'),
        fotoPresidente: require('../Images/cup_presidente.png'),
        //himno: require('../Himnos/himno_.mp3),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#fff200",
        año: "1986",
        redesSociales: {
            web: "cup.cat",
            facebook: "unitatpopular",
            twitter: "91796121",
            whatsapp: "",
            instagram: "cupnacional"
        },
        informacionPartido: {
            "Portavoz del partido:": "Carles Riera",
            "Año de fundación:": "14 de diciembre de 1986",
            "Escaños actuales (Congreso):": "2",
            "Sede:": "Plaza de Gispert, 4, 1º, Manresa",
            "Ideología:": "Pancatalanismo, Independentismo catalán, Euroescepticismo fuerte, Anticapitalismo, Feminismo, Socialismo, Asamblearismo, Antifascismo, Desobediencia",
            "Posición:": "Extrema izquierda",
            "Más información:" : {
                "Programa Electoral": "cup.cat/documents",
            }
        }
    },
    {
        key: '15',
        nombre: "Partido Animalista Contra el Maltrato Animal",
        siglas: "PACMA",
        fotoPartido: require('../Images/pacma.png'),
        fotoPresidente: require('../Images/pacma_presidente.png'),
        //himno: require('../Himnos/himno_.mp3),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#ffffff",
        año: "2003",
        redesSociales: {
            web: "pacma.es",
            facebook: "PartidoAnimalista.PACMA",
            twitter: "84054978",
            whatsapp: "",
            instagram: "partidopacma"
        },
        informacionPartido: {
            "Presidenta del partido:": "Silvia Barquero",
            "Año de fundación:": "24 de febrero de 2003",
            "Escaños actuales (Congreso):": "0",
            "Sede:": "Calle Preciados 11, 28013 Madrid",
            "Ideología:": "Derechos de los animales, Movimiento de liberación animal, Ecologismo, Democracia participativa, Pacifismo",
            "Posición:": "Centroizquierda",
            "Más información:" : {
                "Programa Electoral": "pacma.es/programa-electoral",
            }
        }
    },
    {
        key: '16',
        nombre: "VOX",
        siglas: "VOX",
        fotoPartido: require('../Images/vox.png'),
        fotoPresidente: require('../Images/vox_presidente.png'),
        himno: require('../Himnos/himno_vox.mp3'),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#ffffff",
        año: "2013",
        redesSociales: {
            web: "voxespana.es",
            facebook: "vox.espana",
            twitter: "2201623465",
            whatsapp: "",
            instagram: "vox_es"
        },
        informacionPartido: {
            "Presidente del partido:": "Santiago Abascal",
            "Año de fundación:": "7 de diciembre de 2013",
            "Escaños actuales (Congreso):": "52",
            "Sede:": "Calle de Diego de León, 60, 28006 Madrid",
            "Ideología:": "Neolibrealismo, Nacionalismo español, Conservadurismo, Patriotismo español, Nacionalismo centrista, Reivindicacion caza y tauromaquia, Rechazo al aborto",
            "Posición:": "Derecha, Extrema derecha",
            "Más información:" : {
                "Programa Electoral": "voxespana.es/espana/programa-electoral-vox",
            }
        }
    },
    {
        key: '17',
        nombre: "Democracia Nacional",
        siglas: "DEMOCRACIA NACIONAL",
        fotoPartido: require('../Images/dn.png'),
        fotoPresidente: require('../Images/dn_presidente.png'),
        himno: require('../Himnos/himno_dn.mp3'),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#004f97",
        año: "1995",
        redesSociales: {
            web: "democracianacional.org",
            facebook: "DNdemocracianacional",
            twitter: "345257174",
            whatsapp: "",
            instagram: ""
        },
        informacionPartido: {
            "Presidente del partido:": "Manuel Canduela",
            "Año de fundación:": "25 de mayo de 1995",
            "Escaños actuales (Congreso):": "0",
            "Sede:": "Calle Colmenar de Oreja, 19 (Alcalá de Henares)",
            "Ideología:": "Neonazismo, Nacionalismo español, Euroescepticismo, Fascismo, Proteccionismo, Antiinmigración",
            "Posición:": "Extrema derecha",
            "Más información:" : {
                "Programa Electoral": "democracianacional.org/dn/conocenos/",
            }
        }
    },
    {
        key: '18',
        nombre: "Partido Comunista de España",
        siglas: "PCE",
        fotoPartido: require('../Images/pce.png'),
        fotoPresidente: require('../Images/pce_presidente.png'),
        himno: require('../Himnos/himno_pce.mp3'),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#FFF",
        año: "1921",
        redesSociales: {
            web: "pce.es",
            facebook: "elPCE",
            twitter: "16579721",
            whatsapp: "",
            instagram: "el_pce"
        },
        informacionPartido: {
            "Secretario general del partido:": "Enrique Santiago",
            "Año de fundación:": "14 de noviembre de 1921",
            "Escaños actuales (Congreso):": "4",
            "Sede:": "C/ Olimpo, 35, 28043 Madrid",
            "Ideología:": "Comunismo, Marxismo-leninismo, Internacionalismo, Republicanismo, Feminismo, Euroescepticismo suave, Antifascismo, Anticapitalismo",
            "Posición:": "Izquierda, Extrema izquierda"
        }
    },
    {
        key: '19',
        nombre: "Equo",
        siglas: "EQUO",
        fotoPartido: require('../Images/equo.png'),
        fotoPresidente: require('../Images/equo_presidente.png'),
        //himno: require('../Himnos/himno_.mp3'),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#8aba18",
        año: "2011",
        redesSociales: {
            web: "partidoequo.es",
            facebook: "EQUOpartido",
            twitter: "187864310",
            whatsapp: "",
            instagram: "partido_equo"
        },
        informacionPartido: {
            "Coportavoz del partido:": "Carmen Molina",
            "Año de fundación:": "4 de junio de 2011",
            "Escaños actuales (Congreso):": "1",
            "Sede:": "Calle de Martín de Vargas, 7, 28012 Madrid",
            "Ideología:": "Ecología política",
            "Posición:": "Izquierda",
            "Más información:" : {
                "Programa Electoral": "partidoequo.es/que-hacemos/programas-electorales/",
            }
        }
    },
    {
        key: '20',
        nombre: "Falange Española de las JONS",
        siglas: "FALANGE",
        fotoPartido: require('../Images/falange.png'),
        fotoPresidente: require('../Images/falange_presidente.png'),
        himno: require('../Himnos/himno_falange.mp3'),
        //himnoCorto: require('../Himnos/himno_.mp3),
        colorPartido: "#d70135",
        año: "1976",
        redesSociales: {
            web: "falange.es",
            facebook: "falangistas",
            twitter: "179202323",
            whatsapp: "34635941194",
            instagram: "falangistas"
        },
        informacionPartido: {
            "Líder del partido (Jefe Nacional):": "Norberto Pico Sanabria",
            "Año de fundación:": "4 de octubre de 1976",
            "Escaños actuales (Congreso):": "0",
            "Sede:": "C/ Carranza 13 2º, 28004, Madrid",
            "Ideología:": "Nacionalsindicalismo, Nacionalismo español, Neofascismo, Tercera posición, Euroescepticismo",
            "Posición:": "Extrema derecha",
            "Más información:" : {
                "Programa Electoral": "falange.es/programas-electorales",
            }
        }
    }
];


const publicidad = {
    /*testDeviceId: "E40FA1696EC05952DD7D11853D3061C8",           // Id de mi telefono en EXPO
    bannerId: "ca-app-pub-5523719235882180/9742982951",         // BANNER Id publi BUENA
    interstitialId: "ca-app-pub-5523719235882180/7018124957",   // INTERSTITITAL Id publi BUENA
    bannerId: "ca-app-pub-3940256099942544/6300978111",       // BANNER Id publi TEST
    interstitialId: "ca-app-pub-3940256099942544/1033173712"  // INTERSTITIAL Id publi TEST*/
}


export { partidos, publicidad };