import { fetch } from "fetch-awesome";
import { Platform } from "react-native";
import Constants from "expo-constants";

const timeout = 5000;
const host = "https://www.larryrider.es/apps/partidos/";
const actualizar_datos = host.concat("actualizar_partidos.php")

const URLGetIP = "http://ip-api.com/json";

const getIp = function (callback, callbackError) {
    fetch(URLGetIP, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
        },
        timeout: timeout,
        retries: 1
    }).then((response) => {
        return response.json();
    }).then((responseJson) => {
        //console.warn("respuesta GETIP: " + JSON.stringify(responseJson));
        callback(responseJson);
    }).catch((error) => {
        //console.warn("error GETIP: " + error);
        callbackError(error);
    });
}

const getPhoneInfo = function (info) {
    if (info == "phoneVersion") {
        if (Platform.OS == "web") {
            return "web";
        }
        return parseInt(Platform.Version, 10);
    }
    if (info == "version") {
        return Constants.manifest.version;
    }
    if (info == "phone") {
        if (Platform.OS === 'ios') {
            return Constants.platform.ios.model;
        }
        return Constants.deviceName;
    }
}

export const enviarInfo = function (partido, accion) {
    if (Platform.OS == "web") {
        let json = "{";
        json = json.concat('"partido":"', partido, '",');
        json = json.concat('"accion":"', accion, '",');
        json = json.concat('"phoneVersion":"', getPhoneInfo("phoneVersion"), '",');
        json = json.concat('"version":"', getPhoneInfo("version"), '",');
        json = json.concat('"phone":"', getPhoneInfo("phone"), '"');
        json = json.concat("}");

        return fetch(actualizar_datos, {
            method: "POST",
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            body: json,
            timeout: timeout,
            retries: 1
        }).then((response) => {
            //console.warn(response);
        }).catch((error) => {
            //console.warn(error);
        });
    }
    getIp(function (response) {
        let formData = new FormData();
        formData.append("ip", JSON.stringify(response));
        formData.append("partido", partido);
        formData.append("accion", accion);
        formData.append("phoneVersion", getPhoneInfo("phoneVersion"));
        formData.append("version", getPhoneInfo("version"));
        formData.append("phone", getPhoneInfo("phone"));

        fetch(actualizar_datos, {
            method: "POST",
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            body: formData,
            timeout: timeout,
            retries: 1
        }).then((response) => {
            //console.warn(response);
        }).catch((error) => {
            //console.warn(error);
        });
    }, function (error) {
        let formData = new FormData();
        formData.append("partido", partido);
        formData.append("accion", accion);
        formData.append("phoneVersion", getPhoneInfo("phoneVersion"));
        formData.append("version", getPhoneInfo("version"));
        formData.append("phone", getPhoneInfo("phone"));

        fetch(actualizar_datos, {
            method: "POST",
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            body: formData,
            timeout: timeout,
            retries: 1
        }).then((response) => {
            //console.warn(response);
        }).catch((error) => {
            //console.warn(error);
        });
    });
}